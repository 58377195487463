import React, { useState } from 'react';
import WaveAnimation from './WaveAnimation';
import CustomeTextInput from './CustomeTextInput';
import logo from '../assest/logo.png';

interface HeroSectionProps {
  isScrolled: boolean;
  logo: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({isScrolled, logo}) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await fetch('https://uwcwwlvt42.execute-api.us-east-1.amazonaws.com/dev/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
        
        if (response.ok) {
          setIsSubmitted(true);
          setEmail("");
          setTimeout(() => setIsSubmitted(false), 3000);
        } else {
          throw new Error('Failed to submit your email')
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      }
    }
  };

  return (
    <div  className="relative bg-black text-white overflow-hidden ">
      {/* Hero Content */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-12 pb-4 md:pt-16 md:pb-8 relative z-10 mt-20">
        <div className="max-w-6xl mx-auto  items-center">
        {!isScrolled && (
            <div className="flex items-center text-xl sm:text-2xl lg:text-3xl font-bold tracking-tight">
              <img src={logo} alt="Logo" className="w-10 h-10 mr-2" />
              <span className='font-josefin font-medium text-[45px]'>Evovance</span>
            </div>
          )}
          
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mb-3 md:mb-4 mt-5 font-inter font-medium text-[64px] leading-[80px] tracking-[0.72px] uppercase">
            {"EVOLVE EVERY DAY"} 
          </h1>
          <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6 w-3/4">
            {"Unlock your potential with tools designed to help you grow—one habit, one step, one breakthrough at a time."}
          </p>
          <div className="mb-6">
            <h3 className="text-sm sm:text-base md:text-lg mb-1 md:mb-2 font-bold">
              Get updates
            </h3>
            <form 
              onSubmit={handleSubmit} 
              className="mt-2 flex flex-col items-start space-y-2 w-full"
            >
              <div className="flex flex-col sm:flex-row sm:items-center w-full max-w-md space-y-2 sm:space-y-0 sm:space-x-2">
             <CustomeTextInput email={email} setEmail={setEmail} />
              </div>
              {isSubmitted && (
                <span className="text-green-500 text-xs sm:text-sm">
                  Thank you for submitting your email! We will be in touch soon.
                </span>
              )}
            </form>
          </div>
        </div>
      </div>
      
      {/* Wave animation container */}
        <div className=" bottom-0 left-0 right-0 w-full z-0">
          <div >
            <WaveAnimation />
          </div>
        </div>
    </div>
  );
};

export default HeroSection;