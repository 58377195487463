import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Mission from './pages/Mission';
import Habitspace from './pages/HabitSpace';
import Footer from './pages/Footer';
import AboutUs from './pages/AboutUs';
import "@fontsource/inter";
import "@fontsource/dm-sans";
import "@fontsource/roboto-serif";
import "@fontsource/josefin-sans"; // Defaults to weight 400
import "@fontsource/josefin-sans/300.css"; // Light weight
import "@fontsource/josefin-sans/700.css"; // Bold weight
import { useEffect, useState } from 'react';
import HeroSection from './components/HeroSection';
import logo from '../src/assest/logo.png';
function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 100); // Trigger when scrolled past 100px
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Router>
    <div>
      <Navbar isScrolled={isScrolled} logo={logo} />
      <main>
        <div id="home" className='bg-black text-white'>
          <HeroSection isScrolled={isScrolled} logo={logo} />
        </div>
        <div id="mission">
          <Mission />
        </div>
        <div id="habitspace">
          <Habitspace />
        </div>
        <div id="about">
          <AboutUs />
        </div>
        <div id="contact">
          <Footer />
        </div>
      </main>
    </div>
  </Router>
  );
};


export default App;
