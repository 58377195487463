import React from 'react';
import pen from '../assest/pen.png';
import bell from '../assest/bell.png';
import pencil from '../assest/pencil.png';

const Mission: React.FC = () => {

  
    return (
      <div className="max-w-6xl mx-auto px-4 py-16">
      {/* Mission Section */}
      <div className="text-center mb-16">
        <h2 className="text-yellow-500 text-3xl font-bold mb-4 font-robotoSerif">Our Mission</h2>
        <h1 className="text-5xl font-bold mb-16 font-inter">Tools for personal growth</h1>
        
        <div className="bg-gray-100 rounded-md p-10 mb-16">
          <p className="text-xl leading-relaxed text-center font-bold text-[20px] leading-[30px] max-w-6xl">
            "At Evovance, we believe that personal growth is a journey—one that should be accessible, supported, and 
            sustainable. Our mission is to empower individuals with science-backed tools that inspire real, lasting 
            transformation."
          </p>
        </div>
        
        <p className="text-xl text-gray-700 leading-relaxed max-w-6xl mx-auto">
          We envision a world where personal growth is not a struggle but a natural, empowering journey. A world where self-
          improvement is seamless, supported by technology, community, and science. Evovance is here to reshape how 
          individuals evolve—making transformation not just possible, but inevitable.
        </p>
      </div>
      
      {/* Principles Section */}
      <div className="mb-16">
        <h2 className="text-4xl font-bold text-center mb-16">Our Principles</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Holistic Well-being */}
          <div className="bg-gray-100 rounded-[20px] p-5">
            <div className="flex mb-6">
              <div className="bg-black rounded-full p-6">
                <img src={bell} alt="Holistic Well-being" className="w-8 h-8" />
             
              </div>
            </div>
            <h3 className="text-2xl font-bold mb-4 font-robotoSerif">Holistic Well-being</h3>
            <p className="text-gray-700 font-dmSans">
              Integrate mental, physical, and emotional wellness into every tool we build, ensuring balanced self-improvement.
            </p>
          </div>
          
          {/* Scientifically-Validated Approach */}
          <div className="bg-gray-100 rounded-[20px] p-5">
            <div className="flex mb-6">
              <div className="bg-black rounded-full p-6">
                {/* <svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25z"></path>
                </svg> */}
                 <img src={pen} alt="Holistic Well-being" className="w-8 h-8" />
              </div>
            </div>
            <h3 className="text-2xl font-bold mb-4 font-robotoSerif">Scientifically-Validated Approach</h3>
            <p className="text-gray-700 font-dmSans w-4/5">
            Backed by research in psychology, neuroscience, and behavioral science.
            </p>
          </div>
          
          {/* Supportive Ecosystem */}
          <div className="bg-black text-white rounded-[20px] p-5">
            <div className="flex  mb-6">
              <div className="bg-white rounded-full p-6">
              <img src={pencil} alt="Holistic Well-being" className="w-8 h-8" />
              </div>
            </div>
            <h3 className="text-2xl font-bold mb-4 font-robotoSerif">Supportive Ecosystem</h3>
            <p className='font-dmSans '>
              A combination of digital tools, guided programs, and an engaged community to fuel progress.
            </p>
          </div>
        </div>
      </div>
    </div>
    );
  };
  export default Mission;