import React, { useState } from 'react';
import { Link } from 'react-router-dom';
interface NavbarProps {
  isScrolled: boolean;
  logo: string;
}
const Navbar: React.FC<NavbarProps> = ({ isScrolled, logo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
    setIsMenuOpen(false); // Close mobile menu after clicking
  };
  return (
    <nav className="fixed w-full top-0 z-50 py-4 px-6 bg-black text-white shadow-md">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
      <Link
          to="/#home"
          className="flex items-center text-xl sm:text-2xl lg:text-3xl font-bold tracking-tight"
          onClick={() => scrollToSection('home')}
        >
          {isScrolled && (
            <>
              <img src={logo} alt="Logo" className="w-10 h-10 mr-2" />
              <span className='font-josefin font-medium text-[40px]'>Evovance</span>
            </>
          )}
          {!isScrolled && <span></span>}
        </Link>

        {/* Mobile menu button */}
        <button
          className="md:hidden focus:outline-none p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle menu"
        >
          <svg className="w-6 h-6 sm:w-7 sm:h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {isMenuOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            )}
          </svg>
        </button>

        {/* Desktop menu */}
        <ul className="hidden md:flex items-center space-x-6 lg:space-x-8">
          <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
            <button
              onClick={() => scrollToSection('mission')}
              className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
            >
              Mission
            </button>
          </li>
          <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
            <button
              onClick={() => scrollToSection('habitspace')}
              className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
            >
              HabitSpace
            </button>
          </li>
          <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
            <button
              onClick={() => scrollToSection('about')}
              className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
            >
              About us
            </button>
          </li>
          <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
            <button
              onClick={() => scrollToSection('contact')}
              className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
            >
              Contact
            </button>
          </li>
        </ul>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-black py-4">
          <ul className="flex flex-col space-y-4 px-6">
            <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
              <button
                onClick={() => scrollToSection('mission')}
                className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
              >
                Mission
              </button>
            </li>
            <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
              <button
                onClick={() => scrollToSection('habitspace')}
                className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
              >
                HabitSpace
              </button>
            </li>
            <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
              <button
                onClick={() => scrollToSection('about')}
                className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
              >
                About us
              </button>
            </li>
            <li className='font-inter text-base leading-[36px] tracking-[0.15px]'>
              <button
                onClick={() => scrollToSection('contact')}
                className="text-sm lg:text-base hover:text-gray-300 transition-colors duration-200"
              >
                Contact
              </button>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;