interface CustomeTextInputProps {
  email: string;
  setEmail: (email: string) => void;
  style?: React.CSSProperties;
}

export default function CustomeTextInput({ email, setEmail, style }: CustomeTextInputProps) {
  return (
    <div className="flex  border border-gray-600 rounded-full overflow-hidden bg-white   w-[50%] sm:w-full" style={style}>
      <input
        type="email"
        placeholder="Enter your email here"
        className="px-2 py-1.5 sm:px-3 sm:py-2 md:px-4 md:py-2.5 bg-transparent text-black outline-none w-full 
                   text-xs sm:text-sm md:text-base placeholder:font-dmSans placeholder:text-[#0C0C0C]"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button
        type="submit"
        className="whitespace-nowrap px-2 py-[0.335rem] sm:px-4 sm:py-2 md:px-3 md:py-[0.205rem] bg-[#0C0C0CE5] text-white 
             font-medium rounded-[20px] hover:bg-gray-800 transition-colors text-xs sm:text-sm md:text-base 
             font-dmSans h-full self-center mr-[0.650rem] leading-[0.9] sm:leading-[0.8]"
      >
        Join
      </button> 
    </div>
  );
}