import React from 'react';
import waveImage from '../assest/wave.png';

const WaveAnimation: React.FC = () => {
  return (
    <div className="left-0 w-full h-64">
      <img src={waveImage} alt="Wave" className="w-full h-full  " />
    </div>
  );
};

export default WaveAnimation;