import React, { useState } from "react";
import habits from "../assest/habits.png";
import CustomeTextInput from "../components/CustomeTextInput";

interface HabitspaceProps {
  title?: string;
  subtitle?: string;
  description?: string;
}

const Habitspace: React.FC<HabitspaceProps> = ({
  title = "Habitspace",
  subtitle = "Social habit tracking",
  description = "Crush your goals with a team cheering you on every step of the way. Create habits, track progress, and form groups that keep you accountable and motivated—because together, success is unstoppable.",
}) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await fetch('https://uwcwwlvt42.execute-api.us-east-1.amazonaws.com/dev/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
        
        if (response.ok) {
          setIsSubmitted(true);
          setEmail("");
          setTimeout(() => setIsSubmitted(false), 3000);
        } else {
          throw new Error('Failed to submit your email')
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      }
    }
  };
  return (
    <div className="flex items-center justify-center w-full bg-black text-white px-6 py-12 md:px-12 lg:px-20">
      <div className="relative max-w-6xl mx-auto w-full">
        <div className="relative flex flex-col md:flex-row items-center gap-8 bg-transparent rounded-lg">
          {/* Image Section */}
          <div className="w-full md:w-1/2 flex justify-center">
            <div className="relative w-[80%] sm:w-[75%] md:w-[548px] h-auto">
              <img
                src={habits}
                alt="Team collaboration"
                className="w-full h-auto object-cover rounded-[32px]"
              />
            </div>
          </div>

          {/* Text Content Section */}
          <div className="w-full md:w-1/2 px-4 sm:px-8 md:px-6 flex flex-col justify-center text-center md:text-left">
            <h3 className="text-yellow-500 text-lg sm:text-xl font-semibold mb-2 font-robotoSerif">
              {subtitle}
            </h3>
            <h2 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold font-inter leading-tight">
              {title}
            </h2>
            <p className="text-gray-300 text-base sm:text-lg font-dmSans mt-4">
              {description}
            </p>
            <div className="w-full max-w-md mx-auto md:mx-0 mt-6">
            <h3 className="text-sm sm:text-base md:text-lg mb-2 ">Get updates</h3>
            <form 
              onSubmit={handleSubmit} 
              className="mt-2 flex flex-col items-start space-y-2 w-full"
            >
            <div className="justify-center items-center flex w-[100%]">
            <CustomeTextInput email={email} setEmail={setEmail} />
            </div>
            {isSubmitted && (
                <span className="text-green-500 text-xs sm:text-sm">
                  Thank you for submitting your email! We will be in touch soon.
                </span>
              )}
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Habitspace;
